// Plugins and Libraries
import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lity';
import Rellax from 'rellax';
import SmoothScroll from 'smooth-scroll';

// Styles to compile to the frontend
import './style.scss';

// Other Scripts
// import './scripts/test'; remove from prod
import './scripts/nav';
import './scripts/sliders';
import './scripts/accordion';
// import './scripts/loadMore';


// init Rellax
$(document).ready(function () {
    if ($('.rellax')) {
        var rellaxin = new Rellax('.rellax', {
            center: true,
        });
    }
})

//init Smooth Scroll
var scroll = new SmoothScroll('a[href*="#"]', {
    header: '#header'
});
