import $ from 'jquery';

//opening the first tab by default
$('.tab-group .tab-header').each(function() {

	if ($(this).hasClass('open')) {
		// Let accordion remain open
	}
	else {
		$(this).next('.tab-content').hide();
	}

});

$('.tab-group').on('click', '.tab-header', function() {

	var $tab = $(this),
		$content = $tab.next('.tab-content');

	$tab.toggleClass('open');
	$content.slideToggle();

});