/**
*
*	Sliders
*		Initiates all sliders ont he site
*
**/

import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
    $('.testimonial-slider').slick({
        dots: true,
        fade: true
    });

    $('.image-large-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true,
        asNavFor: '.image-thumbs-slider',
    });

    $('.image-thumbs-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.image-large-slider',
        dots: false,
        arrows: true,
        centerMode: false,
        focusOnSelect: true
    });

});



