/**
*
*	Nav
*		Toggles classes needed to animate the nav
*
**/

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import $ from 'jquery';

$(document).ready(function() {
    const header = document.querySelector("header");
    const navButton = document.querySelector(".menu-btn");
    const nav = document.querySelector('.menu-primary-container');

    navButton.addEventListener('click', handleClick);

    function handleClick() {
        navButton.classList.toggle('open');

        if (navButton.classList.contains("open")) {

            disableBodyScroll(nav);

            header.classList.add("open");
            setTimeout(function() {
                nav.classList.add("open-active");
            }, 150);
        } else {

            enableBodyScroll(nav);

            nav.classList.remove("open-active");
            setTimeout(function() {
                header.classList.remove("open");
            }, 300);
        }
    }

    $(document).ready(function() {
        $(".has-submenu").hover(
            function() {
                $(this).children("ul").stop(true).slideDown('medium');
            },
            function() {
                $(this).children("ul").stop(true).slideUp('medium');
            }
        );
    });
});

